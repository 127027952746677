.lia-rank {
  display: flex;
  padding: 0;
  align-items: center;
  max-width: 240px;

  &-icon {
    height: var(--lia-icon-size-16);
    max-width: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  &-label {
    display: inline-flex;
    max-width: 100%;
    width: fit-content;
    font-size: var(--lia-bs-small-font-size);
    font-weight: var(--lia-bs-font-weight-normal);
    color: var(--lia-bs-gray-900);
    align-items: center;
    line-height: 1.09090909;

    &.lia-is-boxed {
      font-size: var(--lia-font-size-xxs);
      font-weight: var(--lia-font-weight-md);
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-radius: 3px;
      border: 1px solid transparent;
      padding: 1px 3px;
    }

    &.lia-is-filled {
      background-color: var(--lia-bs-gray-900);
      color: var(--lia-bs-white) !important;
      border-color: var(--lia-bs-gray-900);
    }

    &.lia-is-outline {
      border-color: var(--lia-bs-gray-900);
    }

    &.lia-is-text {
      max-height: var(--lia-bs-font-size-base);
      line-height: normal;
    }
  }
}

.text-break {
  word-break: break-all;
  overflow-wrap: anywhere;
}
