@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_panel.pcss';

.lia-badge {
  &-unearned {
    filter: grayscale(100%);
    opacity: 50%;
  }

  @media (--lia-breakpoint-down-md) {
    width: var(--lia-icon-size-40);
    height: var(--lia-icon-size-40);
    min-width: var(--lia-icon-size-40);
    min-height: var(--lia-icon-size-40);
  }
}
